import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Hero({ hero }) {
  return (
    <div className="relative bg-gray-900">
      {/* Decorative image and overlay */}
      <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
        <GatsbyImage
          image={{ ...hero.gatsbyImageData }}
          alt="hero"
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 bg-gray-900 opacity-50"
      />

      <div className="relative mx-auto flex max-w-3xl flex-col items-center py-10 px-6 text-center sm:py-24 lg:px-0">
        <h1 className="text-4xl font-extrabold tracking-tight text-white lg:text-6xl">
          Embalajes MTM
        </h1>
        <p className="mt-4 text-sm sm:text-base text-white">
          Somos una empresa dedicada a la fabricación y distribución de insumos
          para embalajes, para la industria, pymes y grandes empresas. Contamos
          con más de 15 años de experiencia.
        </p>
        <a
          href="#categorias"
          className="mt-8 inline-block rounded-md border border-transparent bg-sky-900 py-3 px-8 text-base font-medium text-white hover:bg-sky-700"
        >
          Ver Productos
        </a>
      </div>
    </div>
  )
}
