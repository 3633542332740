import * as React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import Categories from '../components/Categories'

const IndexPage = ({ data }) => {
  const categories = data.allDatoCmsCategory.nodes
  const hero = data.datoCmsIndexPage.hero
  const seoMetaTags = data.datoCmsIndexPage.seoMetaTags

  return (
    <Layout seoMetaTags={seoMetaTags}>
      <Hero hero={hero} />
      <Categories categories={categories} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allDatoCmsCategory {
      nodes {
        name
        subtitle
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        slug
      }
    }

    datoCmsIndexPage {
      hero {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
