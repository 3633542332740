import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Categories(data) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-12 sm:py-24 lg:max-w-none lg:py-16">
          <h2 id="categorias" className="text-2xl font-extrabold text-gray-900">
            Categorías
          </h2>
          <div className="mt-6 grid grid-cols-2 gap-4 space-y-0 lg:grid-cols-4 lg:gap-6">
            {data.categories.map(category => (
              <div key={category.name} className="group relative">
                <div className="relative h-40 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:h-64">
                  {category.image && (
                    <GatsbyImage
                      image={{ ...category.image.gatsbyImageData }}
                      alt="foto"
                      className="h-full w-full object-cover object-center"
                    />
                  )}
                </div>
                <h3 className="mt-2 sm:mt-4 text-base font-semibold text-gray-800 hover:text-sky-900">
                  <Link to={`/productos/${category.slug}`}>
                    <span className="absolute inset-0" />
                    {category.name}
                  </Link>
                </h3>
                <p className="text-sm text-gray-500">{category.subtitle}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
